.createProjectFile-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }
  
  .createProjectFile-info {
    display: flex;
  }
  .createProjectFile-content {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 900px;
  }
  
  .createProjectFile-header {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .createProjectFile-body .createProjectFile-info {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .createProjectFile-icon {
    font-size: 5rem;
    margin-right: 1rem;
  }
  
  .createProjectFile-text {
    display: flex;
    flex-direction: column;
  }
  
  .createProjectFile-name {
    margin: 0;
  }
  
  .createProjectFile-email {
    margin: 0;
    color: gray;
  }
  
  .createProjectFile-details p {
    margin: 0.5rem 0;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .password-reset-form {
    margin-top: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  