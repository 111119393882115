.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.collection-carousel-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 180px;
  height: fit-content;
  gap: 10px; /* Space between cards */
}

.carousel-inner {
  overflow: visible;
}

.collection-card {
  width: 14rem;
  margin: 10px;
  min-height: 60px; /* Fixed height for cards */
  height: fit-content;
  position: relative; /* Needed for absolute positioning of icons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.collection-card .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.collection-card-title {
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 200px;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
}

.icon-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.icon-container i {
  cursor: pointer;
  color: #777;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.title-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 175px;
}

.collection-card-file-count {
  color: #a1a1a1;
}

.icon-container i:hover {
  color: #000;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
  z-index: 1;
}

.carousel-control-prev {
  margin-left: -30px;
}

.carousel-control-next {
  margin-right: -30px;
}

.carousel-indicators {
  bottom: -45px;
}

.accordion {
  overflow: hidden;
}

.carousel-indicators [data-bs-target] {
  background-color: #a1a1a1;
}

.carousel-indicators .active {
  background-color: var(--primary-button);
}

.dropdown-toggle::after {
  display: none !important;
}

.dropup {
  margin: auto 0;
}

.collection-card {
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  cursor: pointer;
}

.collection-card:hover {
  background-color: #e9e9e9;
}

.accordion-header-content {
  display: flex;
  justify-content: space-between; /* Space between the title and the button */
  align-items: center; /* Center items vertically */
  width: 100%; /* Ensures the header content takes up full width */
}

.create-collection-button {
  margin-left: auto;
  margin-right: 1rem;
}

.collapse-container {
  width: 14rem; /* Fixed width */
  overflow: hidden; /* Prevents overflow issues */
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

#collection-toggle-btn {
  color: var(--primary-button);
  margin-right: 4px;
}

.col-icon-main {
  fill: var(--primary-button);
}

.collection-toggle {
  width: 50px;
  transition: width 0.3s ease;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.collection-toggle i {
  flex-shrink: 0;
}

.collection-toggle .collection-text {
  opacity: 0; /* Hide text initially */
  font-size: 1.1rem;
  color: var(--primary-button);
  margin-left: 5px; /* Space between icon and text */
  transition: opacity 0.3s ease;
  white-space: nowrap; /* Prevent text from wrapping */
}

.collection-toggle:hover {
  background-color: var(--secondary-background);
  border-radius: 12px;
  border: 1px solid var(--primary-button);
  width: 170px; /* Adjust width to fit text */
  padding: 4px;
}

.create-collection-card {
  background-color: #007bff;
}

.create-collection-card .collection-card-title {
  color: white;
}

.create-collection-card:hover .collection-card-title {
  color: #007bff;
}

#collection-toggle-x {
  position: absolute;
  right: 8px;
  color: var(--primary-button);
}

.collection-toggle.col-open {
  background-color: var(--secondary-background);
  border-radius: 12px;
  border: 1px solid var(--primary-button);
  width: 100%; 
  padding: 4px;
}

.collection-toggle.col-open .collection-text {
  opacity: 1;
}
.collection-toggle.col-closed {

}

.collection-toggle:hover .collection-text {
  opacity: 1;
}

.cards-container {
  margin: 0 4px;
}

@media (max-width: 1000px) {
  .collection-card {
    width: calc(50% - 20px);
  }

  .collection-carousel-item {
    height: auto;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }
}

@media (max-width: 600px) {
  .collection-card {
    width: calc(100% - 20px);
  }

  .icon-container {
    bottom: 10px;
    right: 10px;
  }
}

