.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.container {
    display: flex;
    justify-content: space-between; /* This will push the title to the left and the button to the right */
    align-items: center; /* This will vertically align them if they have different heights */
}

.title {
    margin-right: auto; /* This ensures that the title stays left-aligned */
}

.create-button {
    margin-left: auto; /* This ensures that the button stays right-aligned */
}

.customModal {
    width: 90%; /* Adjust width as needed */
    max-width: none; /* This ensures the modal can go beyond the default max width */
}

.table-hover thead th {
color: black; /* Default color */
cursor: pointer; /* Change cursor to pointer on hover */
}

.table-hover thead th:hover {
color: blue; /* Change text color to blue on hover */
text-decoration: underline; /* Underline text on hover */
}

.admin-panel-container {
    display: block;
    width: 1200px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
}

.admin-panel-title {
    padding-bottom: 5px;
}
