  .templateQuestions-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }
  
  .templateQuestions-info {
    display: flex;
  }
  .templateQuestions-content {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 900px;
  }
  
  .templateQuestions-header {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .templateQuestions-body .templateQuestions-info {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .templateQuestions-icon {
    font-size: 5rem;
    margin-right: 1rem;
  }
  
  .templateQuestions-text {
    display: flex;
    flex-direction: column;
  }
  
  .templateQuestions-name {
    margin: 0;
  }
  
  .templateQuestions-email {
    margin: 0;
    color: gray;
  }
  
  .templateQuestions-details p {
    margin: 0.5rem 0;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .password-reset-form {
    margin-top: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .radio-label-spacing .form-check-label {
    margin-right: 20px;
  }

  .templateQuestions-container .input-group-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Ensures it adjusts automatically */
    padding: 0.375rem 0.75rem; /* Matches the padding of the FormControl */
    line-height: 1.5; /* Ensures the text is vertically centered */
    border-radius: 0; /* Optional, depending on your design */
    }
  