/* uploadv2.css */

.upload-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 700px;
}

.dropzone.focused {
    border-color: #2196f3;
}

.dropzone.accept {
    border-color: #00e676;
}

.dropzone.reject {
    border-color: #ff1744;
}

.remove-all-button {
    background-color: white;
    border: solid 2px #ff1744;
    color:#ff1744;
    border-radius: 4px;
}

#remove-btn:hover {
    cursor: pointer;
}