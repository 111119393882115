#root {
  --primary-background: #f0f4fc;
  --secondary-background: #fafafa;
  --primary-button: #3869ad;
  --secondary-button: #f7f9fb;
  --header-font-color: #3b6bae;
  --header-button-color: #4472b2;
  --tertiary-button-color: rgb(172, 172, 172);
  height: 100%;
  overflow-y: hidden;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
