.sidebar {
    height: calc(100% - 127px);
    width: 60px;
    background-color: var(--primary-background);
    transition: width 0.5s;
    position: absolute;
    display: block;
    top: 77px;
    color: black;
    z-index: 500;
  }
  
  .sidebar.expanded {
    width: 280px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .sidebar ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 1rem 0;
    gap: 1rem;
    height: 100%;
  }
  
  .sidebar li {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 48px;
    cursor: pointer;
    margin-bottom: 4px;
  }

  .sidebar.expanded li {
    padding: 0;
  }
  
  .sidebar .bi {
    font-size: 20px;
  }
  
  .sidebar .text {
    margin-left: 10px;
    display: none;
    white-space: nowrap;
  }
  
  .sidebar.expanded .text {
    display: inline;
  }

  .primary-button {
    color: var(--header-font-color);
    text-decoration: none;
  }

  .secondary-button {
    color: var(--header-font-color);
    text-decoration: none;
  }

  .primary-button:hover {
    background-color: rgb(245, 245, 245)
  }

  .sidebar.expanded .secondary-button {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    color: black;
    text-decoration: none;
    display: inline;
    background-color: var(--secondary-button);
    padding: .25rem .5rem;
    border-radius: 8px;
    padding: 8px 16px;
  }

  .sidebar.expanded .secondary-button:hover {
    color: rgb(22, 22, 22);
    background-color: rgb(233, 233, 233);
  }

  .sidebar.expanded .primary-button {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: inline;
    background-color: var(--primary-button);
    padding: .25rem .5rem;
    border-radius: 8px;
    padding: 8px 16px;
    position: relative;
  }

  .sidebar.expanded .primary-button:hover {

    color: rgb(255, 255, 255);

    background-color: rgb(62, 80, 129);

  }

  .sidebar.collapsed .primary-button:hover,
  .sidebar.collapsed .secondary-button:hover {
    background-color: rgba(182, 182, 182, 0.2);
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(179, 179, 179, 0.5); /* Optional shadow for hover effect */
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .settings-header {
    color: #a0a1a3;
    font-size: smaller;
  }

  .expand-icon-container {
    background-color: white;
    color: var(--header-font-color);
    border: solid var(--header-font-color) .5px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 2rem;
    position: absolute;
    top: 8px;
    left: 20px;
  }

  .thin-line {
    height: 1px; /* Thickness of the line */
    background-color: #ccc; /* Color of the line */
    width: 100%; /* Ensures it spans the width of the container */
  }

  .bi-file-earmark-font {
    font-size: 1.5em; /* 10% larger */
  }

  .thin-line.collapsed {
    display: none;
  }

  .expand-icon-container:hover {
    background-color: rgba(182, 182, 182, 0.2);
    box-shadow: 0 0 10px rgba(179, 179, 179, 0.5); /* Optional shadow for hover effect */
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }

  