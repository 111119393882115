@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.chat-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.hs-cnt {
  display: flex;
  align-items: stretch;
}


.history-sidebar {
  width: 300px;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: visible; /* Ensure no hidden overflow */
  height: 100%;
  position: sticky;
  left: 0;
  bottom: 0;
  top: 0;
}

.history-sidebar h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.history-sidebar.hide {
  display: none;
}

.history-sidebar.show {
  display: block;
}

.chat-session-group {
  margin-bottom: 20px;
}

.chat-session-group h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.chat-session {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  overflow: visible;
  position: relative;
}
.title-span{
  max-width: 15rem;
  overflow: hidden;
}
 
 
.chat-session:hover {
  background-color: #ececec;
}


.chatContentWrap {
  display: flex; /* Use flexbox to lay out children side by side */
  flex-direction: column;
  width: 910px;
  position: relative;
  justify-content: center;
  padding: .25rem 0;
  height: 100%;
  margin: 0 auto;
}


.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 910px;
  flex-shrink: 1;
  border-radius: 10px;
  position: relative;
}

.chat-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.message-list {
  flex: 1;
  display: flex;
  flex-direction: column; 
  padding: 10px;
  align-items: flex-start;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure children stretch to full width */
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  white-space: pre-line;
  width: 100%; /* Set width to occupy full container */
  box-sizing: border-box; /* Include padding and border in width */
}



.message ol {
  list-style-position: inside;
  padding-left: 16px;
  margin: 5px 0;
}

.message ul {
  list-style-position: inside;
  padding-left: 16px;
  margin: 5px 0;
}

.message pre div {
  border-radius: 10px;
  overflow: hidden;
}

.human-message {
  font-weight: 400;
  align-self: flex-end;
  justify-content: flex-end;
  text-align: right;
  position: relative;
  padding-right: 2rem; /* Add space for the icon */
}

.human-message::after {
  content: url('../../images/IconSet.png');
  color: #000000;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(10%) translateX(10%);
}

.ai-message {
  align-self: flex-start;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  padding-left: 2rem; /* Add space for the icon */
}

.ai-message::before {
  content: url('../../images/lionai_logosmall.png'); /* Unicode for the robot icon */
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(7%) translateX(-15%);
  /* margin-top: 6px; */
}

.message-input:focus {
  outline: var(--tertiary-button-color) thin solid;
}

.send-button {
  background-color: transparent;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.send-button:hover {
  color: #4d4d4d;
}

.resetContainer {
  text-align: center;
  padding: 10px;
}
.reset-button {
  background-color: #888d88;
  color: #ffffff;
  border-width: 0px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out; 
}

.reset-button:hover {
  background-color: #ff0000;
}

#copy-button {
  color: var(--tertiary-button-color);
  position: absolute;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button-human {
  bottom: 0;
  right: 32px;
}

.copy-button-ai {
  bottom: 0;
  left: 32px;
}

#history-toggle {
  position: relative;
  margin-left: 1rem;
  margin-top: 1rem;
  height: min-content;
  padding-right: .5rem;
  border-radius: 20%;
  display: flex;
  align-items: center;
  transition: width 0.3s ease; /* Smooth width transition */
  max-width: min-content;
  flex-grow: 0;
  /* overflow: hidden; Hide overflowing content */
}

.history-text {
  opacity: 0;
  margin-left: 5px;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  font-size: 1.25rem;
  margin-left: 4px;
  cursor: pointer;
}

#hist-text-exp {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  height: 100%;
}

#history-toggle:hover {
  width: fit-content;
}

#history-toggle:hover .history-text {
  opacity: 1; /* Show text on hover */
}

#copy-button:hover {
  background-color: rgb(240, 240, 240);
}


.bottom-spinner-wrapper {
  display: flex;
  justify-content: center; /* Center the spinner horizontally */
  align-items: center;    /* Center the spinner vertically */
  padding-top: 10px; /* Add a small padding to create space between the last message and spinner */
}


.input-container {
  display: flex;
  align-items: flex-end; /* Center items vertically */
  padding: 4px 10px;
  margin: 10px 0;
  width: 100%;
  position: sticky;
  background-color: white;
  border: 1px solid #ccc; /* Border around the entire container */
  border-radius: 8px; /* Optional: round the corners of the border */
}

.input-container-wrapper{
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: white;
  flex-direction: column;
  align-items: flex-end;
}

.message-input {
  flex: 1;
  resize: none; 
  font-family: 'Roboto', sans-serif;
  padding: 8px 10px;
  border: none; 
  font-size: 1rem;
  /* height: 38px; */
  max-height: 200px; 
}

.message-input:focus {
  outline: none; /* Remove default outline */
}

.message-input::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.send-button {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 4px;
  height: 100%; /* Full height of the input container */
}

.send-button i {
  font-size: 1.5rem;
  color: #000; /* Adjust the color as needed */
}

.send-button:hover {
  color: #4d4d4d;
}

.chat-session.active {
  background-color: #e6e6e6;
}

.no-chat-history {
  padding: 20px;
  text-align: center;
  color: var(--tertiary-button-color);
  font-size: 14px;
}

#new-chat-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 6px;
  border-radius: 8px;
}

#new-chat-icon:hover {
  cursor: pointer;
  background-color: hsla(0, 0%, 87%, 0.4);
}

.collection-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.collection-name-message {
  font-weight: bold;
  font-style: italic;
}

.collection-select {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.selected-collection {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 6px 12px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.selected-collection span {
  margin-right: 8px;
}

.selected-collection i {
  font-size: 12px;
  color: #333;
  cursor: pointer;
}

.custom-dropdown {
  position: relative;
  display: flex;  
  justify-content: flex-end;
  align-items: center;
  width: 400px; /* Adjust as needed */
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.selected-collection-display {
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-collection-display:hover {
  background-color: #f0f0f0;
}

.dropdown-options {
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0; /* Aligns the dropdown to the right side */
  width: 50%; /* Make the dropdown the same width as the select */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-option {
  padding: 10px;
  border-bottom: 1px solid #eee;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; 
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.bi {
  margin-left: 10px;
  cursor: pointer;
}

.chatting-with {
  padding-right: 10px; /* Spacing between "Chatting with" and collection name */
  font-style: italic;
  color: #858585;
  display: inline-block;
  background-color: var(--primary-background);
  border-radius: 0 8px 8px 0;
}


.session-options {
  display: flex;
  justify-content: flex-end;
  width: 25%; /* Ensure that the options (icons) take up 25% of the space */
  align-items: center;
}
.session-options i {
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px; /* Add some spacing between the icons */
}

.menu-icon {
  font-size: 1rem;
  cursor: pointer;
}
.dropdown-menu-history {
  position: absolute; /* Keep absolute so it's relative to the parent container */
  top: 100%; /* Dropdown directly below the three dots */
  right: 0; /* Align it to the right of the parent container */
  transform: translateY(-100%); /* Move it upward */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it appears above everything else */
  display: flex;
  flex-direction: column;
  min-width: 120px;
  overflow: visible; /* Prevent it from getting clipped */
}

.dropdown-item-history {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}
.dropdown-item-history i {
  margin-right: 8px; /* Add some spacing between icon and text */
}

.dropdown-item-history:hover {
  background-color: #f0f0f0;
}

.dropdown-item-history:last-child {
  border-bottom: none;
}

.dropdown-item-history svg {
  margin-right: 8px; /* Space between the icon and text */
}

.editing-session-title {
  display: flex;
  align-items: center;
  position: relative; /* Ensure positioning is relative to this container */
  width: 100%; /* Make sure it doesn't overflow the session box */
}

.editing-session-title input {
  flex: 1; /* Take up available space */
  margin-right: 10px; /* Add spacing between the input and the icons */
  width: 100%; /* Ensure input takes up the available space */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}
.editing-session-title .save-icon, .editing-session-title .cancel-icon {
  font-size: 16px; /* Adjust the size of the icons */
  cursor: pointer;
  padding: 10px; /* Increase padding to make them easier to click */
  border-radius: 50%; /* Make the clickable area circular */
}

.editing-session-title .save-icon {
  color: green;
  margin-right: 5px; /* Add space between the icons */
}

.editing-session-title .cancel-icon {
  color: red;
}
.icon-container {
  display: flex;
  gap: 10px; /* Add space between the icons */
}

.dropdown-tool-options {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 0; /* Aligns the dropdown to the right side */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-tool-option {
  padding: 10px;
  border-bottom: 1px solid #eee;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; 
  cursor: pointer;
}

.selected-tools-count {
  position: absolute;
  bottom: -2px;  /* Adjust as needed */
  right: -8px;   /* Adjust as needed */
  background-color: var(--primary-background);  /* Customize bubble color */
  color: black;
  border-radius: 50%;
  box-shadow: 1px 1px 1px;
  padding: 2px 5px;
  font-size: 0.65rem; /* Adjust font size as needed */
  line-height: 1;
  text-align: center;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-icon-container {
  margin-bottom: 4px;
  margin-right: 4px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.h-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 0 2rem;
  width: 100%;
}

.hist-cards-container {
  margin: 0 4px;
  padding: 8px;
  width: 100%;
}

.history-toggle {
  width: 50px;
  transition: width 0.3s ease;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: sticky;
  top: 16px;
  left: 32px;
  margin-bottom: 2rem;
}

.history-toggle.history-closed {
  width: 50px;
  overflow: hidden;
  height: min-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: width 0.3s ease;
}

.history-toggle i {
  flex-shrink: 0;
}

.history-toggle .history-text {
  opacity: 0; /* Hide text initially */
  font-size: 1.25rem;
  padding-left: .25rem;
  color: var(--primary-button);
  margin-left: 5px; /* Space between icon and text */
  transition: opacity 0.3s ease;
  white-space: nowrap;
}

.history-toggle:hover {
  background-color: var(--secondary-background);
  border-radius: 12px;
  border: 1px solid var(--primary-button);
  width: 170px; /* Adjust width to fit text */
  padding: 4px;
}

#history-toggle-x {
  position: absolute;
  right: 8px;
  color: var(--primary-button);
}

#history-toggle-btn {
  color: var(--primary-button);
  font-size: 1.25rem;
}

.hist-header {
  display: flex;
  position: relative;
}

.history-toggle.history-open {
  background-color: var(--secondary-background);
  border-radius: 12px;
  border: 1px solid var(--primary-button);
  width: fit-content;
  padding: 4px;
  flex-direction: column;
  height: fit-content;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  margin-top: 1rem;
  margin-right: 2rem;
}

.history-toggle:hover .history-text {
  opacity: 1;
}

.history-toggle.history-open .history-text {
  opacity: 1;
}

