.repo-page-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 2rem;
}

.cont-con {
 flex: 1;
 margin-left: 2rem;
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.library-wrapper {
  width: 100%;
  max-width: 1000px;
}

.header-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.header-container-upload {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1rem;
}

.header-container h3,
.header-container-upload .upload-modal {
  margin-right: 20px;
  padding: 0;
  display: flex;
  align-items: flex-end;
}

.icon-wrapper {
  display: flex;
}

#trash-icon {
  padding: 0 4px;
}

#trash-icon:hover {
  cursor: pointer;
}

.add_collection_icon {
  text-decoration: none;
  margin-left: 4px;
}

.clear-collection-filter-btn {
  margin-left: 8px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
}

.file-lib-container {
  max-width: 1000px;
  margin-top: 2rem;
  justify-content: space-between;
  display: flex;
}

.search-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 40%;
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 5px 10px;
}

.search-bar i {
  color: #6c757d;
  margin-right: 8px;
}

.search-input {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 5px;
  font-size: 14px;
}

.file-count {
  margin-left: 16px;
  font-size: 14px;
  color: #6c757d; /* muted text color */
  display: flex;
  align-items: center;
}

th.sortable {
  cursor: pointer;
  position: relative;
  user-select: none;
}

th.sortable:hover {
  color: #007bff; /* Change text color on hover */
  text-decoration: underline;
}

.collections-container {
  margin: 1rem 0;
}

.collections-container.col-open {
  padding: 0 8px 8px 8px;
  border: hsla(219, 35%, 55%, 0.596) solid 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  border-radius: 10px;
  padding: 4px;
  height: fit-content;
  max-height: calc(100% - 32px);
  width: min-content;
}

.collections-container.col-closed {
  border: none;
  width: 40px;
  padding: 4px;
}
