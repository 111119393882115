@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

#app-container {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow-y: auto; */
}

label {
  display: block;
  margin-top: 10px;
}

.appContent {
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  transition: margin-left 0.5s;
  margin-left: 60px;
  overflow-y: auto;
  position: relative;
}

.appContent.no-margin {
  margin-left: 0;
}

.appContent.expanded {
  margin-left: 280px; /* Width of expanded sidebar */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.corpLogo {
  max-height: 50px;
}

.appFooter {
  margin-top: auto;
  width: 100%;
  height: 50px;
  background-color: var(--secondary-background);
  color: var(--header-font-color);
  border-top: 1px solid var(--)
}

.appFooter a {
  color: var(--header-font-color);
}

.footerSplit {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
}

.lionFederalLogo {
  max-height: 30px;
}

.panel-container {
  display: flex;
  gap: 8px;
}

.header {
  height: 76px;
  padding: .5rem 5rem;
  background-color: var(--secondary-background);
}

.header-text-logo {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--header-font-color);
}

.logout-button {
  color: white;
  background-color: var(--header-button-color);
  text-decoration: none;
  margin-left: .5rem;
  border: var(--header-button-color) solid 1px;
}

.logout-button:hover {
  background-color: white;
  color: var(--header-button-color);
}

.logout-link {
  text-decoration: none;
  color: white;
}

.logout-link:hover {
  color: var(--header-button-color);
}

.feedback-button {
  color: var(--header-button-color);
  border: var(--header-button-color) 1px solid;
}

.feedback-button:hover {
  background-color: var(--header-button-color);
  color: white;
}

.login-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.login-branding-wrapper {
  background-color: var(--primary-background);
  height: 100%;
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-wrapper {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 60%; 
}

#sign-in-header {
  align-self: flex-start;
  padding-bottom: 1.5rem;
}

.branding-logo-container {
  margin: 2rem 0;
  background-image: url('./images/lion_ai_transparent_2.png');
  background-size: cover;
  background-position: center;
  background-color: transparent;
  width: 70%;
  aspect-ratio: 1;
  border-radius: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem;
}

.login-button {
  width: 70%;
  margin: 0 auto;
}

.branding-logo-container h1 {
  margin: 1rem 0;
  padding-top: .5rem;
}

.branding-logo-container p {
  line-height: 2;
}

.login-button {
    color: rgb(255, 255, 255);
    background-color: var(--primary-button);
    padding: .25rem .5rem;
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
}

.login-button:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(62, 80, 129);
}

.login-button-container {
  display: flex;
  justify-content: center;
}