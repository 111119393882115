.projecttemplate-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    overflow: visible;
  }

  .projecttemplate-header {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .templates-info {
    display: flex;
  }
  .projecttemplate-content {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 900px;
    min-height: 100px;
  }
  
  .templates-header {
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }

  .add-project-button {
    margin-left: auto;
    margin-right: 1rem;
  }
  .create-project-button {
    margin-left: auto;
    margin-right: 1rem;
  }
  
  .projecttemplate-body .templates-info {
    position: relative;
    z-index:0;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    overflow: visible;
    flex-direction: column;
  }

  .overflow-visible-property {
    overflow: visible;
  }

  .Select__menu {
    z-index: 100; /* High z-index to ensure it appears on top */
  }
  
  .templates-icon {
    font-size: 5rem;
    margin-right: 1rem;
  }
  
  .templates-text {
    display: flex;
    flex-direction: column;
  }
  
  .templates-name {
    margin: 0;
  }
  
  .templates-email {
    margin: 0;
    color: gray;
  }
  
  .templates-details p {
    margin: 0.5rem 0;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .password-reset-form {
    margin-top: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .template-panel-title {
    padding-bottom: 10px;
  }

  .small-dropdown .dropdown-menu {
    font-size: 12px !important;
  }

  .small-dropdown .dropdown-toggle {
    font-size: 12px;      /* Adjust font size */
    padding: 4px 4px;     /* Adjust padding to make it smaller */
    height: 35px;         /* Adjust height to auto or smaller if needed */
    width: auto;          /* Adjust width if necessary */
  }

  .dropdown-menu {
    margin-top: 0 !important;   /* Remove any extra margin at the top */
    padding-top: 0 !important;  /* Remove extra padding at the top */
  }


  .small-dropdown .dropdown-menu {
    position: absolute !important; /* Forces the dropdown to be in absolute position */
    top: 100%; /* Positions it directly below the button */
    left: 0; /* Aligns it directly to the left edge of the button */
    margin-top: 4px; /* Optional: slight spacing below the button */
    z-index: 1050; /* Keeps it above other elements */
  }
  

  .status-badge {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    padding: 4px 6px;
    border-radius: 8px;
    width: 90px;
  }
  
  .status-badge.uploaded {
    background-color: #aec6e8;
    color: #0d428f;
  }
  
  .status-badge.processing {
    background-color: #ffbe45;
    color: #8f5d00;
  }
  
  .status-badge.active {
    background-color: #a4ebaf;
    color: #206b2c;
  }
  
  .status-badge.rejected {
    background-color: #edada1;
    color: #c72b0e;
  }
  
  .status-badge.deleting {
    background-color: #e34c30;
    color: #f7f1f0;
  }
  
  .status-badge.default {
    background-color: #d1dcf8;
    color: #5173c9;
  }
  
  .status-loading {
    margin-left: 0.5rem;
    color: #ffc107; /* Bootstrap warning color */
    font-size: 1rem;
  }
  